<template>
  <div class="card">
    <div class="card-body p-10">
      <div class="row">
        <div class="col">
          <ul class="nav mb-10">
            <li class="nav-item">
              <a
                class="
                  nav-link
                  btn btn-lg btn-color-muted btn-active btn-active-light-primary
                  active
                  fw-bolder
                  px-4
                  me-1
                "
                data-bs-toggle="tab"
                href="#kt_table_widget_7_tab_1"
                >基本資料</a
              >
            </li>

            <li class="nav-item">
              <a
                class="
                  nav-link
                  btn btn-lg btn-color-muted btn-active btn-active-light-primary
                  fw-bolder
                  px-4
                  me-1
                "
                data-bs-toggle="tab"
                href="#kt_table_widget_7_tab_2"
                >設施與服務</a
              >
            </li>

            <li class="nav-item">
              <a
                class="
                  nav-link
                  btn btn-lg btn-color-muted btn-active btn-active-light-primary
                  fw-bolder
                  px-4
                "
                data-bs-toggle="tab"
                href="#kt_table_widget_7_tab_3"
                >設定</a
              >
            </li>
            <li class="nav-item">
              <a
                class="
                  nav-link
                  btn btn-lg btn-color-muted btn-active btn-active-light-primary
                  fw-bolder
                  px-4
                  me-1
                "
                data-bs-toggle="tab"
                href="#kt_table_widget_7_tab_4"
                >QR Code</a
              >
            </li>
          </ul>
        </div>
        <div class="col d-flex justify-content-end align-items-center">
          <el-switch v-model="isEdit" active-text="開啟修改"></el-switch>
          <button
            v-if="isEdit"
            type="button"
            class="btn btn-primary ms-3"
            style="height: 45.44px"
            @click="update()"
          >
            送出
          </button>
        </div>
      </div>

      <div class="tab-content">
        <div class="tab-pane fade active show" id="kt_table_widget_7_tab_1">
          <!--begin::Table container-->
          <div class="table-responsive">
            <!--begin::Table-->
            <BasicInformation
              :facilityData="facilityData"
              :isEdit="isEdit"
            ></BasicInformation>
          </div>
          <!--end::Table-->
        </div>
        <div class="tab-pane fade" id="kt_table_widget_7_tab_2">
          <!--begin::Table container-->
          <div class="table-responsive">
            <!--begin::Table-->
            <FacilitiesAndServices
              :facilityData="facilityData"
              :isEdit="isEdit"
            ></FacilitiesAndServices>
          </div>
          <!--end::Table-->
        </div>
        <div class="tab-pane fade" id="kt_table_widget_7_tab_3">
          <!--begin::Table container-->
          <div class="table-responsive">
            <!--begin::Table-->
            <Settings
              :facilityData="facilityData"
              :isEdit="isEdit"
              @changeCapacity="changeCapacity"
            ></Settings>
          </div>
          <!--end::Table-->
        </div>
        <div class="tab-pane fade" id="kt_table_widget_7_tab_4">
          <!--begin::Table container-->
          <div class="table-responsive">
            <!--begin::Table-->

            <QrCode :facilityData="facilityData"></QrCode>
          </div>
          <!--end::Table-->
        </div>
      </div>
    </div>
  </div>
</template>

<script lang='ts'>
import { defineComponent, reactive, ref, watch } from "vue";
import localstorage from "@/core/services/LocalstorageService";
import BasicInformation from "./components/BasicInformation.vue";
import QrCode from "./components/QrCode.vue";
import FacilitiesAndServices from "./components/FacilitiesAndServices.vue";
import Settings from "./components/Settings.vue";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { useRouter, useRoute } from "vue-router";
import { getFacility, updateFacility } from "@/core/services/api/facilities";

export default defineComponent({
  components: {
    BasicInformation,
    FacilitiesAndServices,
    Settings,
    QrCode,
  },
  emits: ["changeCapacity"],
  setup() {
    const facilityData = reactive({
      type: "Facility",
      mode: "Standard",
      status: "Public",
      information: [
        {
          icon: "string",
          title: "string",
          content: "string",
        },
      ],
      theme: {
        coverPhoto: "string",
        images: ["string"],
        locationImages: ["string"],
      },
      setting: {
        availableDays: 0,
        invitationNumber: 0,
        minuteToEnter: 0,
        minuteToCancel: 0,
        minuteToExtend: 0,
        minuteToCheckOut: 0,
        autoRelease: true,
        delayCaptureInDays: 0,
        canDoorAccess: true,
        canIotFetch: true,
        canDistribute: true,
        canInvite: true,
        canUserCheckOut: true,
        canUserFeedback: true,
      },
      guid: "string",
      space: "string",
      id: "string",
      name: "string",
      category: "string",
      createTime: "2021-11-10T01:35:14.477Z",
      description: "string",
      openingHoursDescription: "string",
      location: "string",
      quota: 0,
      displayOrder: 0,
      usageNotice: "string",
      priceTag: "string",
    });
    const router = useRouter();
    const route = useRoute();
    const currentSpaceGuid = localstorage.getCurrentSpaces()?.space.guid;
    let isEdit = ref(false);
    const spaceData: any = reactive({});
    const facilityGuid = route.params.deviceGuid;

    const setFacilityData = async () => {
        console.log("facilityGuid",facilityGuid)
      let response: any = await getFacility(facilityGuid);
      Object.assign(facilityData, response.facility);
    };

    const changeCapacity = (capacity) => {
      facilityData.setting.invitationNumber = capacity.value - 1;
    };

    const update = async () => {
      await Swal.fire({
        title: "您確定要提交修改嗎?",
        confirmButtonText: "確定",
        denyButtonText: `取消`,
      }).then(async (result) => {
        if (result.isConfirmed) {
          let request = {
            guid: facilityGuid,
            name: facilityData.name,
            category: facilityData.category,
            mode: facilityData.mode,
            status: facilityData.status,
            description: facilityData.description,
            openingHoursDescription: facilityData.openingHoursDescription,
            location: facilityData.location,
            images: facilityData.theme.images,
            information: facilityData.information,
            setting: facilityData.setting,
            displayOrder: facilityData.displayOrder,
            usageNotice: facilityData.usageNotice,
            priceTag: facilityData.priceTag,
          };
          await updateFacility(request);
          Swal.fire("提交成功!", "", "success");
          setFacilityData();
        }
      });
    };

    const init = async () => {
      await setFacilityData();
    };
    init();

    return {
      isEdit,
      facilityData,
      changeCapacity,
      update,
    };
  },
});
</script>
