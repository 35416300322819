
import { computed, defineComponent, ref, watch } from "vue";

export default defineComponent({
  props: ["facilityData", "isEdit"],
  setup(props, { attrs, emit, slots }) {
    const capacity = ref(0);

    watch(
      props,
      () => {
        capacity.value = props.facilityData.setting.invitationNumber+1;
      },
      {
        immediate: true,
        deep: true,
      }
    );

    const onSubmit = () => {
      console.log("onSubmit");
    };

    const changeCapacity = () => {
      emit("changeCapacity", capacity);
    };

    return {
      capacity,
      props,
      onSubmit,
      changeCapacity,
    };
  },
});
