<template>
  <el-form
    id="facilityManagement-basicInformation-facility"
    @submit.prevent="onSubmit()"
    :model="props.facilityData"
    ref="formRef"
    class="form"
    label-width="200px"
  >
    <div class="row" style="width: 100%">
      <div class="col">
        <!-- <el-form-item style="padding: 15px 0; margin: 0" label="可容納人數">
          <el-input
            :disabled="!props.isEdit"
            type="number"
            v-model="capacity"
            @change="changeCapacity()"
          ></el-input>
        </el-form-item> -->
        <el-form-item style="padding: 15px 0; margin: 0" label="可提前預約天數">
          <el-input
            :disabled="!props.isEdit"
            type="number"
            v-model="props.facilityData.setting.availableDays"
          ></el-input>
        </el-form-item>
        <el-form-item
          style="padding: 15px 0; margin: 0"
          label="提前使用時間(分鐘)"
        >
          <el-input
            :disabled="!props.isEdit"
            type="number"
            v-model="props.facilityData.setting.minuteToEnter"
          ></el-input>
          <i>Ex.設定為15分鐘 為10:00開始的票券 可於 09:45開始使用</i>
        </el-form-item>
        <el-form-item
          style="padding: 15px 0; margin: 0"
          label="開始前不可取消時間(分鐘)"
        >
          <el-input
            :disabled="!props.isEdit"
            type="number"
            v-model="props.facilityData.setting.minuteToCancel"
          ></el-input>
          <i>Ex.設定為15分鐘 為10:00開始的票券 於 09:45後不可取消</i>
        </el-form-item>
        <!-- <el-form-item
          style="padding: 15px 0; margin: 0"
          label="結束後自動歸還(分鐘)"
        >
          <el-input
            :disabled="!props.isEdit"
            type="number"
            v-model="props.facilityData.setting.minuteToCheckOut"
          ></el-input>
          <i>Ex.設定為15分鐘 為10:00結束的票券 於 10:15由系統執行自動歸還</i>
        </el-form-item> -->
      </div>
      <div class="col">
        <div>
          <i>(以下調整均不影響已經開立的票券)</i>
        </div>
        <div class="my-7">
          <el-switch
            :disabled="!props.isEdit"
            v-model="props.facilityData.setting.autoRelease"
            active-text="歸還動作"
            :active-value="false"
            :inactive-value="true"
          >
          </el-switch>
          <br />
          <i>執行歸還後是否可再次使用</i>
        </div>
        <div class="my-7">
          <el-switch
            :disabled="!props.isEdit"
            v-model="props.facilityData.setting.canDoorAccess"
            active-text="開門權限"
          >
          </el-switch>
          <br />
          <i>預定此設施/設備 是否擁有開門權限</i>
        </div>
        <div class="my-7">
          <el-switch
            :disabled="!props.isEdit"
            v-model="props.facilityData.setting.canIotFetch"
            active-text="iot權限"
          >
          </el-switch>
          <br />
          <i>預定此設施/設備 是否擁有iot權限</i>
        </div>
        <div class="my-7">
          <el-switch
            :disabled="!props.isEdit"
            v-model="props.facilityData.setting.canDistribute"
            active-text="轉贈功能"
          >
          </el-switch>
          <br />
          <i>預定此設施/設備 是否可使用轉贈功能</i>
        </div>
        <div class="my-7">
          <el-switch
            :disabled="!props.isEdit"
            v-model="props.facilityData.setting.canInvite"
            active-text="邀請功能"
          >
          </el-switch>
          <br />
          <i>預定此設施/設備 是否可使用邀請功能 接受邀請方會取得開門權限</i>
        </div>
        <div class="my-7">
          <el-switch
            :disabled="!props.isEdit"
            v-model="props.facilityData.setting.canUserCheckOut"
            active-text="使用者自行歸還"
          >
          </el-switch>
          <br />
          <i>預定此設施/設備 是否可以讓Spaces Go使用者自行歸還</i>
        </div>
        <!-- <div class="my-7">
          <el-switch
            :disabled="!props.isEdit"
            v-model="props.facilityData.setting.canUserFeedback"
            active-text="使用者填寫回饋與評分"
          >
          </el-switch>
          <br />
          <i>預定此設施 是否可以讓Spaces Go使用者填寫回饋及評分</i>
        </div> -->
      </div>
    </div>
  </el-form>
</template>

<script lang='ts'>
import { computed, defineComponent, ref, watch } from "vue";

export default defineComponent({
  props: ["facilityData", "isEdit"],
  setup(props, { attrs, emit, slots }) {
    const capacity = ref(0);

    watch(
      props,
      () => {
        capacity.value = props.facilityData.setting.invitationNumber+1;
      },
      {
        immediate: true,
        deep: true,
      }
    );

    const onSubmit = () => {
      console.log("onSubmit");
    };

    const changeCapacity = () => {
      emit("changeCapacity", capacity);
    };

    return {
      capacity,
      props,
      onSubmit,
      changeCapacity,
    };
  },
});
</script>

<style>
</style>